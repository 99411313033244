<template>
  <el-dialog
    visible
    :show-close="false"
    append-to-body
    custom-class="rounded issues-modal mt-5 mb-0"
    @close="$emit('close')"
  >
    <template #title>
      <div class="p-4 rounded-top border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <span class="d-flex align-items-center">
            <h2>{{ $t('modals.issues.header') }}</h2>
            <template v-if="supplier && supplier.name">
              <h2 class="mx-2">-</h2>
              <h2>{{ supplier.name }}</h2>
            </template>
          </span>
          <span class="d-flex">
            <el-dropdown
              class="d-flex justify-content-center align-items-center"
              trigger="click"
              :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
              @command="(method) => _self[method]()"
            >
              <Button type="text" class="p-0 text-typography-primary action-btn">
                <KebabIcon />
              </Button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="exportToExcel" :disabled="isEmpty(selectedData)">
                  <div class="d-flex align-items-center">
                    <ExcelIcon height="24" width="24" :class="$direction === 'rtl' ? 'ms-1' : 'me-1'" />
                    {{ $t('modals.issues.kebabButton.exportToExcel') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="exportToEmail" :disabled="isEmpty(selectedData)">
                  <div class="d-flex align-items-center">
                    <EmailIcon height="24" width="24" :class="$direction === 'rtl' ? 'ms-1' : 'me-1'" />
                    {{ $t('modals.issues.kebabButton.exportToEmail') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="me-1" />
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
              <CloseIcon />
            </Button>
          </span>
        </div>
        <div class="d-flex">
          <p>{{ formatMonth(month) }}</p>
          <template v-if="!loading">
            <div class="mx-2">‧</div>
            <p>{{ $t('modals.issues.totalDifferenceAmount', { amount: formatMoney(totalDifferenceAmount) }) }}</p>
            <template v-if="missingDocuments.length">
              <div class="mx-2">‧</div>
              <p>{{ $t('modals.issues.missingDocumentsCount', { count: missingDocuments.length }) }}</p>
            </template>
          </template>
        </div>
      </div>
    </template>
    <div v-loading="loading" class="p-4 h-100">
      <div class="h-100 overflow-auto">
        <template v-if="loading">
          <Billed class="mb-7" />
          <Unbilled class="mb-7" />
          <MissingDocuments class="mb-7" />
          <PaymentDifferences class="mb-7" />
          <UnmatchedPayments class="mb-7" />
          <GoodsExchange />
        </template>
        <template v-else>
          <Billed
            v-if="billings.length"
            :billings="billings"
            :expanded-billing-id="billingId"
            :missing="missingDocuments"
            :class="hasTableBelow('billings')"
            @selected-data="handleSelectedData($event, 'billed')"
            @preview-document="previewDocument"
          />
          <Unbilled
            v-if="unbilled.length"
            :orders="unbilled"
            :supplier="supplier.name"
            :class="hasTableBelow('unbilled')"
            @selected-data="handleSelectedData($event, 'unbilled')"
            @preview-document="previewDocument"
          />
          <MissingDocuments
            v-if="missingDocuments.length"
            :missing="missingDocuments"
            :class="hasTableBelow('missingDocuments')"
            @selected-data="handleSelectedData($event, 'missing')"
            @preview-document="previewDocument"
          />
          <PaymentDifferences
            v-if="paymentDifferences.length"
            :payment-differences="paymentDifferences"
            :class="hasTableBelow('paymentDifferences')"
            @preview-document="previewDocument"
          />
          <UnmatchedPayments
            v-if="unbilledPayments.length"
            :unbilled-payments="unbilledPayments"
            :class="hasTableBelow('unbilledPayments')"
          />
          <GoodsExchange
            v-if="deliveryTasks.length"
            :delivery-tasks="deliveryTasks"
            @preview-document="previewDocument"
          />
        </template>
      </div>
      <DocumentModal
        v-if="previewDocumentId"
        visible
        :document-id="previewDocumentId"
        @close="previewDocumentId = null"
      />
    </div>
    <template #footer>
      <Button type="secondary" @click="$emit('close')">{{ $t('commons.close') }}</Button>
    </template>
  </el-dialog>
</template>

<script>
import { DateTime } from 'luxon';
import { isEmpty, omit, groupBy, uniq, reject, flatten, isNil } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';

import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { KebabIcon, CloseIcon, ExcelIcon, EmailIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useSupplier } from '@/modules/suppliers';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { useUnbilledPayments } from '@/modules/payment';
import { useUnbilledOrders } from '@/modules/billing';
import { useDeliveryTasks } from '@/modules/delivery';
import { useTenancy } from '@/modules/auth';
import { useMissingEvents } from '@/modules/reconciliation';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { DocumentModal } from '@/modules/documentModal';

import { Billed, MissingDocuments, Unbilled, UnmatchedPayments, PaymentDifferences, GoodsExchange } from './components';
import {
  useSupplierActivity,
  useSupplierActivityDocuments,
  setDocumentFromSource,
  prepareIssuesBillings,
  prepareIssuesMissingDocuments,
  exportToExcel,
  usePaymentDifferences,
} from './compositions';

export default {
  components: {
    KebabIcon,
    CloseIcon,
    ExcelIcon,
    EmailIcon,
    Button,
    Billed,
    MissingDocuments,
    Unbilled,
    UnmatchedPayments,
    PaymentDifferences,
    GoodsExchange,
    DocumentModal,
  },
  props: {
    supplierId: { type: String, required: true },
    businessId: { type: String, required: true },
    month: { type: String, required: true },
    billingId: { type: String, required: false, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();

    const { supplier, loading: supplierLoading } = useSupplier(computed(() => props.supplierId));

    const activityVariables = computed(() => ({
      businessId: props.businessId,
      supplierId: props.supplierId,
      fromDate: DateTime.fromISO(props.month, { zone: 'utc' }).toISODate(),
      toDate: DateTime.fromISO(props.month, { zone: 'utc' }).endOf('month').toISODate(),
    }));

    const { billings, orders, loading: activityLoading } = useSupplierActivity(activityVariables);
    const { documents, loading: documentsLoading } = useSupplierActivityDocuments(activityVariables);

    const { missingEvents, loading: missingEventsLoading } = useMissingEvents(() => ({
      businessId: props.businessId,
    }));

    const { documents: rehandleParentDocuments, loading: rehandleParentDocumentsLoading } = useDocumentsByIds(
      computed(() => ({
        businessId: props.businessId,
        ids: uniq(
          missingEvents.value
            .filter(({ parentDocumentId }) => parentDocumentId)
            .map(({ parentDocumentId }) => parentDocumentId)
        ),
      }))
    );

    const { paymentDifferences, loading: paymentDifferencesLoading } = usePaymentDifferences(
      computed(() => ({ customerId: props.businessId }))
    );

    const { unbilledPayments, loading: unbilledPaymentsLoading } = useUnbilledPayments(
      computed(() => ({ businessId: props.businessId, supplierId: supplier.value.id }))
    );

    const { unbilledOrders } = useUnbilledOrders(() => ({
      businessId: props.businessId,
      supplierId: supplier.value.id,
    }));

    const ordersWithDocuments = computed(() =>
      orders.value.map((order) => setDocumentFromSource(order, documents.value))
    );

    const { tasks: deliveryTasks, loading: loadingDeliveryTasks } = useDeliveryTasks(
      computed(() => ({
        businessId: props.businessId,
      }))
    );

    const billingOrderIds = computed(() =>
      flatten(
        billings.value.map((b) =>
          reject(
            isNil,
            b.orderLinks.map((ol) => ol.order?.source?.ref)
          )
        )
      )
    );

    return {
      formatCentsToCurrency,
      isEmpty,
      currentTenant,
      supplier,
      root,
      unbilledOrders,
      billings: computed(() => prepareIssuesBillings(billings.value, ordersWithDocuments.value, documents.value)),
      orders: ordersWithDocuments,
      missingEvents,
      rehandleParentDocuments,
      paymentDifferences: computed(() =>
        paymentDifferences.value
          .filter(({ customerPayment }) => !customerPayment)
          .filter(({ supplierPayment }) => supplierPayment.supplierId === supplier.value.id)
      ),
      unbilledPayments,
      deliveryTasks: computed(() =>
        deliveryTasks.value.filter(
          ({ delivery }) =>
            (delivery?.document &&
              delivery.document.supplier.id === props.supplierId &&
              DateTime.fromFormat(delivery.document.issueDate, 'yyyy-MM-dd').toFormat('yyyy-MM') === props.month) ||
            billingOrderIds.value.includes(delivery.document.id)
        )
      ),
      loading: computed(
        () =>
          supplierLoading.value ||
          activityLoading.value ||
          documentsLoading.value ||
          missingEventsLoading.value ||
          paymentDifferencesLoading.value ||
          unbilledPaymentsLoading.value ||
          loadingDeliveryTasks.value ||
          rehandleParentDocumentsLoading.value
      ),
      selectedData: ref({}),
      previewDocumentId: ref(null),
    };
  },
  computed: {
    totalDifferenceAmount() {
      const totalBilledImbalancesAmount = this.billings.reduce(
        (totalImbalancesAmount, billing) => {
          const totalImbalanceAmount = billing.imbalances.reduce(
            (totalImbalance, imbalance) => totalImbalance + imbalance.billedAmount - imbalance.orderedAmount,
            0
          );
          return totalImbalancesAmount + totalImbalanceAmount;
        },

        0
      );

      const totalUnbilledDifferencesAmount = this.unbilled.reduce((totalDifferencesAmount, order) => {
        const totalDifferenceAmount = order.differences.reduce(
          (totalDifferences, difference) => totalDifferences + difference.amount,
          0
        );
        return totalDifferencesAmount + totalDifferenceAmount;
      }, 0);

      return totalBilledImbalancesAmount + totalUnbilledDifferencesAmount;
    },
    missingDocuments() {
      return prepareIssuesMissingDocuments(
        this.missingEvents,
        this.supplierId,
        this.rehandleParentDocuments,
        this.month
      );
    },
    unbilled() {
      const unbilledOrderIds = this.unbilledOrders
        .filter(
          ({ date }) =>
            DateTime.fromISO(date) >= DateTime.fromISO(this.month) &&
            DateTime.fromISO(date) <= DateTime.fromISO(this.month).endOf('month')
        )
        .map(({ orderId }) => orderId);

      return this.orders.filter(({ id }) => unbilledOrderIds.includes(id));
    },
  },
  methods: {
    formatMonth(month) {
      return new Date(month).toLocaleString(this.$i18n.locale, { month: 'short', year: 'numeric' });
    },
    formatMonthName(month) {
      return new Date(month).toLocaleString(this.$i18n.locale, { month: 'long' });
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    formatDate(ms) {
      return ms
        ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short)
        : this.$i18n.t('commons.unknownDate');
    },
    formatQuantity(number) {
      typeof number === 'number' && !Number.isNaN(number) ? Number(number).toLocaleString(this.$i18n.locale) : '';
    },
    exportToExcel() {
      exportToExcel(this.selectedData, this.month, true);
    },
    exportToEmail() {
      const formatShekel = (value) => Number(value).toLocaleString(this.$i18n.locale, currency);
      let body = `${this.root.$t('modals.issues.emails.templates.body.hello')}\n\n`;
      const subject = this.root.$t('modals.issues.emails.templates.subject', {
        month: this.formatMonthName(this.$props.month),
        customer: this.currentTenant.legalName,
      });
      const data = {};
      this.selectedData.billed?.forEach((billed) => {
        billed.orderItems.forEach((orderItem) => {
          if (!data[orderItem.difference?.type]) data[orderItem.difference?.type] = [];
          data[orderItem.difference?.type].push({
            ...orderItem,
            document: billed.document,
          });
        });
      });

      if (this.selectedData.missing && this.selectedData.missing.length > 0) {
        body += `${this.root.$t('modals.issues.emails.templates.body.missingDocument.header', {
          month: this.formatMonthName(this.$props.month),
        })}\n\n`;
        this.selectedData.missing.forEach((m) => {
          body += `${this.root.$t('modals.issues.emails.templates.body.missingDocument.missing', {
            documentNumber: m.documentName,
            documentType: m.documentType,
            date: m.date ? this.formatDate(m.date) : '',
          })}\n`;
        });

        body += '\n';
      }

      if (data.quantity && data.quantity.length > 0) {
        body += `${this.root.$t('modals.issues.emails.templates.body.creditByQuantity.header', {
          month: this.formatMonthName(this.$props.month),
          amount: formatShekel(
            data.quantity.reduce((total, { difference: { quantityAmount } }) => total + quantityAmount, 0)
          ),
        })}\n\n`;
        const diffsByDoc = groupBy(({ document: { id } }) => id, data.quantity);
        Object.values(diffsByDoc).forEach((diffs) => {
          body += `${this.root.$t('modals.issues.emails.templates.body.creditByQuantity.documentAndDate', {
            date: this.formatDate(diffs[0].orderDate),
            documentNumber: diffs[0].document.documentNumber,
            documentType: this.root.$t(`document.exports.schema.type.shortName.${diffs[0].document.type}`),
          })}\n`;

          diffs.forEach(({ difference }) => {
            const quantity = difference.quantity.supplierValue - difference.quantity.customerValue;
            body += `${this.root.$t('modals.issues.emails.templates.body.creditByQuantity.diff', {
              productSku: difference.product.name,
              quantity,
              price: formatShekel(difference.quantityAmount / quantity),
              total: formatShekel(difference.quantityAmount),
            })}\n`;
          });

          body += '\n';
        });
      }
      if (data.pricing && data.pricing.length > 0) {
        body += `${this.root.$t('modals.issues.emails.templates.body.creditByPrice.header', {
          month: this.formatMonthName(this.$props.month),
          amount: formatShekel(
            data.pricing.reduce((total, { difference: { pricingAmount } }) => total + pricingAmount, 0)
          ),
        })}\n\n`;
        const diffsByDoc = groupBy(({ document: { id } }) => id, data.pricing);
        Object.values(diffsByDoc).forEach((diffs) => {
          body += `${this.root.$t('modals.issues.emails.templates.body.creditByPrice.documentAndDate', {
            date: this.formatDate(diffs[0].date),
            documentNumber: diffs[0].document.documentNumber,
            documentType: this.root.$t(`document.exports.schema.type.shortName.${diffs[0].document.type}`),
          })}\n`;

          diffs.forEach(({ difference, quantity }) => {
            body += `${this.root.$t('modals.issues.emails.templates.body.creditByPrice.diff', {
              customerPrice: formatShekel(difference.netPrice.customerValue),
              supplierPrice: formatShekel(difference.netPrice.supplierValue),
              productSku: difference.product.name,
              quantity,
              total: formatShekel(difference.pricingAmount),
            })}\n`;
          });

          body += '\n';
        });
      }

      window.location.href = `mailto:user@example.com?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
    },
    handleSelectedData(data, dataType) {
      if (isEmpty(data)) this.selectedData = omit([dataType], this.selectedData);
      else
        this.selectedData = {
          ...this.selectedData,
          [dataType]: data,
        };
    },
    hasTableBelow(tableName) {
      const tableOrder = [
        { label: 'billings', count: this.billings.length },
        { label: 'unbilled', count: this.unbilled.length },
        { label: 'missingDocuments', count: this.missingDocuments.length },
        { label: 'paymentDifferences', count: this.paymentDifferences.length },
        { label: 'unbilledPayments', count: this.unbilledPayments.length },
        { label: 'deliveryTasks', count: this.deliveryTasks.length },
      ];

      const indexOfTable = tableOrder.findIndex((to) => to.label === tableName);
      const tablesBelow = tableOrder.slice(indexOfTable + 1);
      if (tablesBelow.some((to) => to.count)) return 'mb-7';
    },
    previewDocument(documentId) {
      this.previewDocumentId = documentId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .issues-modal {
  $total-height: 95vh;
  $header-height: 86px;
  $footer-height: 62px;
  $body-height: calc($total-height - $header-height - $footer-height);
  height: $total-height;
  width: 85vw;

  .el-dialog__header {
    height: $header-height;
  }
  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
    color: $typography-primary;
  }

  .el-dialog__body {
    height: $body-height;
  }

  .el-dialog__footer {
    height: $footer-height;
  }
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>
